<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative" :style="`z-index: ${zIndex}`">
      <TransitionChild
        as="template"
        enter="ease-out duration-100"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          :class="[
            ' fixed inset-0 bg-gray-500 bg-opacity-75',
            noTransition ? 'transition-none' : 'transition-opacity',
          ]"
          @click.stop="clickedOverlay"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          :class="[
            'flex min-h-full items-end p-4 text-center sm:items-center sm:p-0 justify-center',
            { 'full-screen w-full h-screen': fullScreen },
            { 'h-full': !fullScreen },
          ]"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              :class="[
                'modal-body relative transform overflow-y-auto bg-white text-left',
                { 'w-screen h-screen': fullScreen },
                { 'rounded-lg': !fullScreen },
                { 'w-5/6 h-5/6': size === 'large' },
                { 'w-1/2 h-1/2': size === 'medium' },
                { 'w-1/3 h-1/3': size === 'small' },
                noTransition ? 'transition-none' : 'transition-all',
              ]"
            >
              <div class="absolute right-0 top-0 hidden pr-3 pt-3 sm:block z-20">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                  @click.stop="closeModal"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-4 w-4" aria-hidden="true" />
                </button>
              </div>
              <div :class="['bg-white', noPadding ? 'h-full' : 'py-6 px-8']">
                <component :is="dynamicComponent" @emittedToModal="emittedToModal($event)" />
                <slot />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useDialog } from "@/plugins/useDialog";
import useInterfaceStore from "@/store/interface";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  dynamicComponent: {
    type: Object,
    default: null,
  },
  fullScreen: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "medium",
    validator: (prop) => ["small", "medium", "large"].includes(prop),
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  noTransition: {
    type: Boolean,
    default: false,
  },
  zIndex: {
    type: Number,
    default: 50,
  },
});
const emit = defineEmits(["update:modelValue", "update:open", "closed", "emittedToModal"]);

const interfaceStore = useInterfaceStore();
const $dialog = useDialog();

const emittedToModal = function (event) {
  emit("emittedToModal", event);
};

function clickedOverlay() {
  console.log("clickedOverlay");
}
function closeModal(event) {
  event.stopPropagation();
  // Check if the modal has unsaved changes by checking the modalUnsavedChanges value in the interface store.
  // If it does, show a confirmation dialog.
  // If it doesn't, close the modal.

  if (interfaceStore.modalUnsavedChanges) {
    $dialog(
      "Unsaved Changes",
      "You have unsaved changes. Closing this modal will discard these changes. Are you sure you want to proceed?",
      "confirm",
      "Close without saving",
      () => {
        emit("update:modelValue", false);
      }
    );
  } else {
    emit("update:modelValue", false);
  }
}
// Watch for changes to modelValue. When it changes from true to false, emit the "closed" event.
watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (oldValue && !newValue) {
      emit("closed");
    }
  }
);
</script>
