<template>
  <div class="h-full overflow-auto">
    <div class="mx-auto mb-12">
      <h2 class="mt-2 text-3xl font-bold tracking-tight mb-2">Analytics Code Checker</h2>
      <p class="text-gray-500">
        Verify the analytics codes installed on a website. Simply enter the website URL below and
        click Analyze to start the analysis.
      </p>
    </div>

    <form @submit.prevent="analyze">
      <gm-input
        v-model="url"
        label="Website URL"
        type="url"
        name="name"
        id="name"
        placeholder="https://"
        size="large"
        required
        :disabled="loading"
      >
        <template v-slot:append>
          <gm-button :loading="loading" :label="buttonLabel" />
        </template>
      </gm-input>
    </form>

    <div class="mt-3">
      <h3 v-if="haveResults && !noTagsFound" class="text-gray-500 text-md pt-3">
        Found the following tags:
      </h3>
      <div v-for="(result, index) in results" :key="index">
        <div v-if="result.present" class="flex bg-white rounded-md border border-gray-200 p-3 mt-3">
          <div class="w-6 h-6 flex items-center mr-3">
            <img :src="result.icon" alt="Icon" class="h-auto" />
          </div>
          <p class="font-semibold text-gray-800 mr-3">
            {{ result.name }}
          </p>
          <!-- Display the name and presence -->
          <p class="text-gray-600">{{ result.ids.join(", ") }}</p>
          <!-- Display the IDs -->
        </div>
      </div>
      <div
        v-if="noTagsFound && haveResults"
        class="bg-gray-100 rounded-md border border-gray-100 p-3 mt-3"
      >
        No tags found.
      </div>
      <div v-if="haveResults && !noTagsFound" class="flex items-center justify-center p-6">
        <a @click.prevent="clearResults" href="#" class="text-gray-400 text-sm hover:text-gray-600"
          >Clear results</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import GmButton from "../../components/gm-components/GmButton.vue";
import GmInput from "../../components/gm-components/GmInput.vue";
import { ref, watch, computed } from "vue";

const loading = ref(false);
const buttonLabel = ref("Analyze");
const url = ref(""); // Initialize the URL
const results = ref([]); // Initialize the results array
// Computed property to check if no tags are found
const noTagsFound = computed(
  () => Array.isArray(results.value) && results.value.every((result) => !result.present)
);
const haveResults = computed(() => results.value && results.value.length > 0);

function clearResults() {
  results.value = [];
  url.value = [];
}

async function analyze() {
  results.value = []; // Clear the previous results
  loading.value = true;
  buttonLabel.value = "Analyzing...";

  // Construct the fetch options
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      url: url.value,
    }),
  };

  // Make the API request
  try {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/api/analyticsTagChecker`, options);
    const data = await response.json();

    // Store the results in the results array
    results.value = Object.keys(data).map((key) => ({ name: key, ...data[key] }));
  } catch (error) {
    // Handle the error here
    console.error("Error:", error);
  } finally {
    loading.value = false;
    buttonLabel.value = "Analyze";
  }
}
</script>
