<template>
  <div>
    <PageHeader title="Tools" />
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      <div
        v-for="(tool, idx) in toolsDetails"
        :key="idx"
        class="divide-y divide-white flex flex-col place-content-between overflow-hidden rounded-lg bg-white shadow"
      >
        <div class="px-4 py-5 sm:p-6">
          <!-- Content goes here -->
          <a
            href="#"
            @click.prevent="launchTool(tool.name, tool.title)"
            class="text-xl font-semibold mb-2 hover:text-brand-500"
            >{{ tool.title }}</a
          >
          <p class="mt-2 text-sm line-clamp-2 text-gray-400">
            {{ tool.description }}
          </p>
        </div>
        <div class="flex w-full px-4 py-4 sm:px-6 align-bottom place-content-between">
          <div class="flex items-center overflow-hidden mr-3"></div>

          <button
            type="button"
            class="inline-flex items-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="launchTool(tool.name, tool.title)"
          >
            Launch
          </button>
        </div>
      </div>
    </div>
    <div v-show="isActiveTool">
      <Modal v-model="modalOpen" :dynamicComponent="activeTool" fullScreen />
    </div>
  </div>
</template>

<script setup>
import PageHeader from "../components/PageHeader.vue";
import Modal from "../components/Modal.vue";
import { computed, reactive, ref, onMounted } from "vue";
import AnalyticsCodeChecker from "./tools/AnalyticsCodeChecker.vue";

const activeTool = ref();
const isActiveTool = computed(() => !!activeTool.value);
const activeToolName = ref();

const modalOpen = ref(false);

const toolsDetails = [
  {
    name: "AnalyticsCodeChecker",
    title: "Analytics Code Checker",
    description:
      "Check which analytics tools a website has installed, including GA4 and Facebook Pixel.",
  },
];

const toolsComponents = {
  AnalyticsCodeChecker,
};

function launchTool(toolComponent, toolName) {
  activeTool.value = toolsComponents[toolComponent];
  activeToolName.value = toolName;
  modalOpen.value = true;
}
</script>
