<template>
  <div
    :class="[
      'bg-white py-10 px-6 -mx-6 mt-4 mb-10 md:flex md:items-center md:justify-between ',
      { ' border-b border-gray-200': computedShowBorder },
    ]"
  >
    <gm-page-container css="items-center flex-col">
      <div class="w-full flex items-center">
        <div class="min-w-0 flex-1 h-full justify-start text-left flex flex-col pr-72">
          <div class="mb-6 -mt-6" v-if="haveBackButton">
            <gm-button
              size="small"
              secondary
              :label="showBackButton.label"
              icon="back"
              class="text-xs uppercase tracking-tight font-medium text-brandDarkBlue-500 hover:opacity-50"
              href="#"
              @click.prevent="goBack(showBackButton.url)"
            ></gm-button>
          </div>
          <div class="flex w-full">
            <slot name="top" />
          </div>
          <div v-if="loading" class="w-full flex">
            <div class="animate-pulse flex flex-1">
              <div class="flex-1 space-y-4">
                <div class="h-8 bg-gray-200 rounded w-3/4"></div>
                <div class="space-y-2">
                  <div class="h-3 bg-gray-200 rounded w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <h2
              class="text-2xl mb-0 font-semibold text-black sm:truncate sm:text-3xl sm:tracking-tight"
              v-html="title"
            ></h2>
            <div v-if="haveSubTitle" class="text-md font-normal text-gray-500 mt-3">
              {{ subTitle }}
            </div>
          </div>

          <div
            class="text-xs text-gray-500 uppercase mt-3"
            v-if="showBreadcrumbs"
            v-html="breadcrumbs"
          />
        </div>
        <div class="mt-4 flex md:mt-0 md:ml-4 items-center">
          <slot name="actions" />
        </div>
      </div>
      <div v-if="haveTabs" class="tabs-container w-full flex -mb-10 mt-8">
        <div
          v-for="tab in tabs"
          :key="tab"
          :class="[
            'bg-white px-3 py-2 text-sm font-medium border-b-2 cursor-pointer',
            tab === computedActiveTab
              ? 'border-brandDarkBlue-500 text-brandDarkBlue-500'
              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
          ]"
          @click="changeTab(tab)"
          v-html="tab"
        ></div>
      </div>
    </gm-page-container>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, onMounted, nextTick, watch } from "vue";
import { useRouter } from "vue-router";
import { useInterfaceStore } from "@/store/interface";
import { ChevronLeftIcon } from "@heroicons/vue/24/solid";

const interfaceStore = useInterfaceStore();
const router = useRouter();

const props = defineProps({
  title: String,
  subTitle: String,
  showBorder: {
    type: Boolean,
    default: true,
  },
  showBackButton: {
    type: Object,
    default: () => ({
      url: "",
      label: "",
    }),
  },
  showBreadcrumbs: {
    type: Boolean,
    default: false,
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  activeTab: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:activeTab"]);

const computedShowBorder = computed(() => {
  // Return true if the prop is set to true or we have tabs
  return props.showBorder || props.tabs.length > 0;
});

// Breadcrumbs
const breadcrumbs = computed(() => createBreadcrumbs());
function createBreadcrumbs() {
  // Get the route parts
  const routeParts = router.currentRoute.value.path.split("/");

  // Remove the first empty part
  routeParts.shift();

  // Remove the last part if it's a number
  if (routeParts[routeParts.length - 1].match(/^\d+$/)) {
    routeParts.pop();
  }

  // Create the breadcrumbs
  const breadcrumbs = routeParts.map((part, index) => {
    const path = routeParts.slice(0, index + 1).join("/");
    return {
      label: part
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      path,
    };
  });

  // Convert to string with links
  // If the current route is the last one in the breadcrumbs, don't make it a link
  const breadcrumbsHtml = breadcrumbs
    .map((breadcrumb, index) => {
      if (index === breadcrumbs.length - 1) {
        return `<span class="text-gray-800 cursor-default">${breadcrumb.label}</span>`;
      }

      return `<a class="breadcrumb-link hover:opacity-50" href="/${breadcrumb.path}" class="breadcrumb-link">${breadcrumb.label}</a>`;
    })
    .join(" / ");

  // As a guardrail, return an empty string if there's only one breadcrumb
  if (breadcrumbs.length === 1) {
    return "";
  }

  return breadcrumbsHtml;
}

const haveSubTitle = computed(() => {
  return props.subTitle && props.subTitle !== "";
});

const haveTabs = computed(() => {
  return props.tabs.length > 0;
});

const computedActiveTab = computed(() => {
  return props.activeTab || props.tabs[0];
});

const haveBackButton = computed(() => {
  return props.showBackButton.url && props.showBackButton.url !== "";
});

function changeTab(newTab) {
  if (newTab !== computedActiveTab.value) {
    emit("update:activeTab", newTab);
  }
}

function navigateTo(url) {
  router.push(url);
}

function goBack(url) {
  router.push(url);
}

onMounted(() => {
  // Grab the hrefs of all breadcrumb links
  const breadcrumbLinks = document.querySelectorAll(".breadcrumb-link");

  // Add an onclick listener to the breadcrumb links to navigate using Vue Router
  breadcrumbLinks.forEach((link) => {
    link.addEventListener("click", (event) => {
      event.preventDefault();
      const path = event.target.href.replace(window.location.origin, "");
      router.push(path);
    });
  });
});
</script>
